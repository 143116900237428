/**
 * Lazy Image component
 * @package DCA
 * @param {Object} $ - DOM selector function (e.g., querySelectorAll)
 */
export default class ImgFade {
    /**
     * UI references
     */
    ui = {
        fadeImages: '.img-fade img',
    };

    /**
     * Constructor
     */
    constructor() {
        this.registerEvents();
    }

    /**
     * Register required events
     */
    registerEvents() {
        // Look for fade images and set up
        this.setupImages(this.ui.fadeImages);
    }

    /**
     * Applies the fade logic for all images, that matches the given selector
     */
    setupImages(selector) {
        const images = document.querySelectorAll(selector);
        images.forEach((image) => {
            if (image instanceof HTMLImageElement && !image.complete) {
                this.setupImg(image);
            }
        });
    }

    /**
     * Setup fade images
     */
    setupImg(img) {
        const mediaBox = img.closest('.img-fade');
        mediaBox.classList.add('img-fade-waiting');
        img.addEventListener('load', this.fadeImageLoad.bind(this));
        img.addEventListener('error', this.fadeImageError.bind(this));
    }

    /**
     * Handle fade image loading
     */
    fadeImageLoad(event) {
        const img = event.currentTarget;
        const mediaBox = img.closest('.img-fade');
        if (mediaBox) {
            mediaBox.classList.remove('img-fade-waiting');
            mediaBox.classList.add('img-fade-loaded');
        }

        // Remove error callback
        img.removeEventListener('error', this.fadeImageError.bind(this));
    }

    /**
     * Handle fade image error
     */
    fadeImageError(event) {
        const img = event.currentTarget;
        const mediaBox = img.closest('.img-fade');
        if (mediaBox) {
            mediaBox.classList.remove('img-fade-waiting');
            mediaBox.classList.add('img-fade-error');
        }

        // Remove load callback
        img.removeEventListener('load', this.fadeImageLoad.bind(this));
    }
}
