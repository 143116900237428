/**
 * Autoplay video component
 * @author Stefan Rueschenberg <Stefan@Humans-Machines.com>
 * @author Raffael Stpken <Raffael@Humans-Machines.com>
 *
 *
 * Add a video placeholder to be turned into a HTML video player
 * We do this to overcome autoplay issues mostly in Safari
 *
 * Script originally from Dinamo
 * We removed the player controls functionionality and IE support
 *
 * Use this template:
 *
 * <div class='js-video-loader'
 *      data-autoplay='1'
 *      data-muted='1'
 *      data-src='[{"media":"screen and (min-width: 1920px)","src":"https:\/\/cdn.abcdinamo.com\/media\/_1920xauto-q60\/Dinamo_Brains_34sec_Loop_03-web_3_2021-10-27-095526.mp4"},{"media":"screen and (min-width: 1280px)","src":"https:\/\/cdn.abcdinamo.com\/media\/_1440xauto-q60\/Dinamo_Brains_34sec_Loop_03-web_3_2021-10-27-095526.mp4"},{"media":"screen and (min-width: 1000px)","src":"https:\/\/cdn.abcdinamo.com\/media\/_960xauto-q60\/Dinamo_Brains_34sec_Loop_03-web_3_2021-10-27-095526.mp4"},{"media":"screen and (min-width: 600px)","src":"https:\/\/cdn.abcdinamo.com\/media\/_960xauto-q60\/Dinamo_Brains_34sec_Loop_03-web_3_2021-10-27-095526.mp4"},{"media":"screen and (max-width: 599px)","src":"https:\/\/cdn.abcdinamo.com\/media\/_640xauto-q60\/Dinamo_Brains_34sec_Loop_03-web_3_2021-10-27-095526.mp4"}]'
 *      data-poster='[{"width":1024,"src":"https:\/\/cdn.abcdinamo.com\/media\/_1024xauto-q90\/ABCDinamo-TeamMember-2.jpg"},{"width":800,"src":"https:\/\/cdn.abcdinamo.com\/media\/_800xauto-q90\/ABCDinamo-TeamMember-2.jpg"},{"width":640,"src":"https:\/\/cdn.abcdinamo.com\/media\/_640xauto-q90\/ABCDinamo-TeamMember-2.jpg"},{"width":480,"src":"https:\/\/cdn.abcdinamo.com\/media\/_480xauto-q90\/ABCDinamo-TeamMember-2.jpg"},{"width":320,"src":"https:\/\/cdn.abcdinamo.com\/media\/_320xauto-q90\/ABCDinamo-TeamMember-2.jpg"}]'
 *      >Video is loading ...</div>
 *
 */


export default class Video {

    /**
     * VIDEO constructor
     */
    constructor() {
        // Initialize the observer and observe elements when the window loads.
        window.addEventListener('load', () => {
            this.initObserver();
            this.observeElements();
        });
    }

    /**
     * Define UI elements
     */
    ui = {
        videoContainer: '.video', // Containing wrapper
        videoPlayer: '.video__player', // HTML Video element
        autoplayVideo: '.video__player[data-autoplay="true"]',
        videoLoader: '.js-video-loader',
    };

    /**
     * Reference of the intersection observer
     */
    intersectionObserver = null;

    /**
     * Indicates, if browser supports intersection observer
     * @return {boolean} True, if the browser support the intersection observer
     */
    useIntersectionObserver() {
        return 'IntersectionObserver' in window;
    }

    /**
     * Initializes the intersection observer
     */
    initObserver() {
        if (!this.useIntersectionObserver()
            || this.intersectionObserver !== null
            || !document.querySelectorAll(this.ui.videoLoader).length
        ) {
            return;
        }

        // Create an observer
        this.intersectionObserver = new IntersectionObserver(this.handleIntersection.bind(this), {
            root: null, // Use the viewport as the root
            rootMargin: '25% 0px 50% 0px', // Extend margin to trigger function more early
            threshold: 0, // Trigger when a single pixel of the element is in the viewport
        });
    }

    /**
     * Observed the related elements
     */
    observeElements() {
        if (!this.intersectionObserver) {
            return;
        }

        // Observe video loaders
        document.querySelectorAll(this.ui.videoLoader).forEach((element) => {
            if (!element.dataset.isObserved) {
                this.intersectionObserver.observe(element);
                element.dataset.isObserved = true;
            }
        });

        // Update intersections
        this.intersectionObserver.takeRecords().forEach(this.handleIntersectionEntry.bind(this));
    }

    // Handle intersection changes
    handleIntersection(entries, observer) {
        entries.forEach(this.handleIntersectionEntry.bind(this));
    }

    // Handle intersection for a single entry
    handleIntersectionEntry(entry) {
        const element = entry.target;

        if (!entry.rootBounds) {
            return;
        }

        const inView = entry.isIntersecting || entry.intersectionRatio > 0;

        if (element.matches(this.ui.videoLoader)) {
            if (!inView) {
                return;
            }
            this.convertLoaderToVideo(element);
        } else {
            if (inView && element.paused) {
                const playPromise = element.play();
                if (playPromise) {
                    playPromise.catch((error) => {
                        if (error.name === 'AbortError') {
                            const retryPlayPromise = element.play();
                            if (retryPlayPromise) {
                                retryPlayPromise.catch((retryError) => {
                                    if (retryError.name === 'NotAllowedError') {
                                        this.onAutoplayNotAllowedError(element);
                                    }
                                    console.warn(retryError);
                                });
                            }
                        } else if (error.name === 'NotAllowedError') {
                            // Maybe because of battery sacve mode
                            this.onAutoplayNotAllowedError(element);
                        }
                        console.warn(error);
                    });
                }
            } else if (!inView && !element.paused) {
                element.pause();
            }
        }
    }

    // Handle not allowed error for autoplay videos
    onAutoplayNotAllowedError(element) {
        const $container = element.parentElement;

        /* No poster yet
        if (element.dataset.poster) {
            // Add poster
            // element.setAttribute('poster', element.dataset.poster);
            console.log(element.dataset.poster);
        }*/

        // Try to fetch poster from Vimeo
        if (element.dataset.vimeo) {
            // Add poster
            this.setPosterFromVimeo(element);
            // console.log(element.dataset.vimeo);
        }

        $container.classList.remove('is-autoplay');
        $container.classList.add('is-autoplay-not-allowed');
        element.removeAttribute('data-autoplay');
        this.intersectionObserver.unobserve(element);
        this.bindVideoEvents($container, true);
    }

    // Convert the loader element to an actual video
    convertLoaderToVideo(element) {
        if (this.useIntersectionObserver()) {
            this.intersectionObserver.unobserve(element);
        }
        const $parent = element.parentElement;
        const { markup, poster, autoplay, vimeo } = this.getVideoMarkupConfig(element);

        // Replace the element with the new markup
        element.outerHTML = markup;

        if (this.useIntersectionObserver() && $parent.querySelector(this.ui.autoplayVideo)) {
            this.intersectionObserver.observe($parent.querySelector(this.ui.autoplayVideo));
        }

        // Continue with other actions as needed
        const $videoContainer = $parent.querySelector('.video');
        $videoContainer.querySelector(this.ui.videoPlayer).dataset.poster = poster;
        $videoContainer.querySelector(this.ui.videoPlayer).dataset.vimeo = vimeo;
        this.bindVideoEvents($videoContainer);
    }

    // Get video markup configuration
    getVideoMarkupConfig(element) {
        const autoplay = parseInt(element.dataset.autoplay) === 1;
        const muted = parseInt(element.dataset.muted) === 1;
        const srcConfig = JSON.parse(element.dataset.src);
        const src = srcConfig.find(item => window.matchMedia(item.media).matches).src;
        const poster = this.getPosterFromLoaderElement(element);
        const containerClasses = ['video', autoplay ? 'is-autoplay' : '', muted ? 'is-muted' : '']
            .filter(cls => cls.length > 0)
            .join(' ');
        const videoAttributes = Object.entries({
            'class': 'video__player',
            'data-autoplay': autoplay ? 'true' : 'false',
            muted: muted,
            loop: autoplay,
            src: src,
            playsinline: true,
            disableremoteplayback: true,
            poster: poster ? poster : false,
        })
            .filter(([key, value]) => typeof value !== 'boolean' || value)
            .map(([key, value]) => {
                if (typeof value === 'boolean') {
                    return key;
                }
                if (!this.useIntersectionObserver() && key === 'data-autoplay' && value === 'true') {
                    return 'autoplay';
                }
                return `${key}="${value}"`;
            })
            .join(' ');
        const markup = `
            <div class="${containerClasses}">
                <video ${videoAttributes}></video>
            </div>
        `;

        return {
            markup,
            autoplay,
            muted,
            src,
            srcConfig: element.dataset.src,
            poster,
            posterConfig: element.dataset.poster,
            vimeo: element.dataset.vimeo,
        };
    }

    // Get the proper poster path
    // Handle multiple sizes if provided
    // Eg. data-poster='[{"width":800,"src":"\/assets\/images\/rueda-800w.jpeg"},{"width":800,"src":"\/assets\/images\/rueda-640w.jpeg"}]'
    getPosterFromLoaderElement(element) {
        if (!element.dataset.poster) {
            return false;
        }

        const posterArray = JSON.parse(element.dataset.poster);

        if (posterArray.length === 0) {
            return false;
        }

        const elementWidth = element.offsetWidth;
        const candidates = posterArray.filter(item => item.width >= elementWidth);

        return candidates.length > 0 ? candidates.pop().src : posterArray.shift().src;
    }

    // Fetch poster from Vimeo API and add to video element
    // @see https://developer.vimeo.com/api/oembed/videos
    setPosterFromVimeo(element) {
        if(element.dataset.vimeo) {
            const thumbSize = window.innerWidth >= 1000 ? 1280 : 640;
            const vimeoVideoUrl = `https://vimeo.com/api/oembed.json?url=https://vimeo.com/${element.dataset.vimeo}&width=${thumbSize}`;

            return fetch(vimeoVideoUrl)
                .then(response => response.json())
                .then(data => {
                    if (data && data.thumbnail_url) {
                        element.setAttribute('poster', data.thumbnail_url);
                    } else {
                        return false;
                    }
                })
                .catch(error => {
                    console.warn("Error fetching Vimeo JSON:", error);
                    return false;
                });
        }
    }


    // Bind video events
    bindVideoEvents($videoContainer, rebind = false) {
        const $video = $videoContainer.querySelector(this.ui.videoPlayer);
        if ($video.dataset.initialized && !rebind) {
            return;
        }
        if (!this.useIntersectionObserver() && $videoContainer.classList.contains('is-autoplay')) {
            $video.autoplay = true;
        }
        if (rebind) {
            $videoContainer.removeEventListener('play', this.onVideoPlay);
            $videoContainer.removeEventListener('pause', this.onVideoPause);
        }
        $video.dataset.initialized = true;
    }
}
