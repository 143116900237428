import Video from './video';
import ImgFade from './image-fade';


// Create module instances
new Video();
new ImgFade();


// *** HELPERS

// Check for reduced motion setting
// const reducedMotion = window.matchMedia('(prefers-reduced-motion: reduce)');

// Check if touch device
const touchDevice = matchMedia('(hover: none)').matches;

// ***


// *** GENERAL

// Get the body element
const bodyElement = document.body;

// ***


// *** SETTINGS

// Define the minimum width for the large breakpoint
const breakpointLgMinWidth = 1000;

// ***


// *** EVENT LISTENERS

// Add an event listener for window load event
window.addEventListener('load', () => {
    // Initial update
    updateViewportWidth();

    // Add loaded class
    document.body.classList.add('is-loaded');
});

// Add an event listener for window resize event
window.addEventListener('resize', () => {
    updateViewportWidth();
});

// Reset all videos on ESC
document.addEventListener('keydown', (event) => {
    if (event.key === 'Escape' || event.keyCode === 27) {
        // User pressed the "Escape" key
        // Call the reset functions here
        resetAllVideoPlayers();
        hideAllIndexVideos();
    }
});

// ***



// *** BODYCLASSES

// Scroll threshold values
const scrollThresholdTop = 40;
const scrollThresholdBottom = 40; // You can adjust this value

function updateScrollClasses() {
    const scrollTop = window.scrollY;
    const pageHeight = bodyElement.scrollHeight - window.innerHeight;

    // Check if scrolled by 40px and add the appropriate class
    if (scrollTop > scrollThresholdTop) {
        bodyElement.classList.add('is-scrolled-some');
    } else {
        bodyElement.classList.remove('is-scrolled-some');
    }

    // Check if scrolled to the bottom of the page and add the appropriate class
    if (scrollTop >= (pageHeight - scrollThresholdBottom)) {
        bodyElement.classList.add('is-scrolled-fully');
    } else {
        bodyElement.classList.remove('is-scrolled-fully');
    }
}

// Add a scroll event listener to the window
window.addEventListener('scroll', updateScrollClasses, {passive: true});

// Call the function once to set initial class state
updateScrollClasses();

/// ***


// *** VIDEO PLAYER

const videoPlayers = document.querySelectorAll('.js-video-player');

if (videoPlayers.length > 0) {
    videoPlayers.forEach((videoPlayer) => {
        // Add click to start video player
        videoPlayer.addEventListener('click', (event) => {
            activateVideoPlayer(videoPlayer);
        });
    });
}

function activateVideoPlayer(videoPlayer) {
    // Activate video player if not activated yet
    if (!videoPlayer.matches('.is-active')) {
        // Reset all other video players
        resetAllVideoPlayers();

        // Add playing class to remove preview video
        videoPlayer.classList.add('is-active');

        // Add active class to specific parent if available
        const videoWrapper = videoPlayer.closest('.js-video-wrapper');
        if (videoWrapper) {
            videoWrapper.classList.add('has-active-video');
        }

        // Load iframe
        const iframeLoader = videoPlayer.querySelector('.js-iframe-loader');

        // Replace dive with iframe markup
        if(iframeLoader) {
            const markup = getIframeMarkupConfig(iframeLoader);
            iframeLoader.outerHTML = markup;
        }

        const iframe = videoPlayer.querySelector('iframe');

        if (iframe && iframe.getAttribute('data-src')) {
            const dataSrcValue = iframe.getAttribute('data-src');
            iframe.setAttribute('src', dataSrcValue);
            iframe.removeAttribute('data-src');
        }

        iframe.onload = function () {
            videoPlayer.classList.add('is-playing');
            iframe.onload = null; // Remove the onload event listener
        };
    }
}

// Get iframe markup configuration
function getIframeMarkupConfig(element) {
    const title = element.dataset.title;
    const src = element.dataset.src;
    // const src = srcConfig.find(item => window.matchMedia(item.media).matches).src;

    const iframeAttributes = Object.entries({
        'class': 'video-player__embed',
        'data-ready': 'true',
        allow: 'autoplay; fullscreen; picture-in-picture',
        src: src,
        title: title
    })
        .filter(([key, value]) => typeof value !== 'boolean' || value)
        .map(([key, value]) => {
            if (typeof value === 'boolean') {
                return key;
            }

            return `${key}="${value}"`;
        })
        .join(' ');

    const markup = `
        <iframe ${iframeAttributes}></iframe>
    `;

    return markup;
}

function resetAllVideoPlayers() {
    videoPlayers.forEach((videoPlayer) => {
        // Remove player classes
        videoPlayer.classList.remove('is-playing');
        videoPlayer.classList.remove('is-active');

        // Remove active class to specific parent if needed
        const videoWrapper = videoPlayer.closest('.js-video-wrapper');
        if (videoWrapper) {
            videoWrapper.classList.remove('has-active-video');
        }

        // Reset iframes to data-src
        const iframe = videoPlayer.querySelector('iframe');

        if (iframe && iframe.getAttribute('src')) {
            const srcValue = iframe.getAttribute('src');
            iframe.setAttribute('data-src', srcValue);
            iframe.removeAttribute('src');
        }
    });
}

/// ***




// *** REEL VIDEOS

const reelsModules = document.querySelectorAll('.js-reels');

if (reelsModules.length > 0) {
    reelsModules.forEach((reelsModule) => {
        const reelsPrevious = reelsModule.querySelector('.js-reels-previous');
        const reelsNext = reelsModule.querySelector('.js-reels-next');

        const reelsLength = reelsModule.dataset.reels;
        let currentReel = 1;

        reelsPrevious.addEventListener('click', e => {
            if(currentReel > 1) {
                currentReel--;
            } else currentReel = reelsLength;

            activateReel(reelsModule, currentReel);
        });

        reelsNext.addEventListener('click', e => {
            if(currentReel < reelsLength) {
                currentReel++;
            } else currentReel = 1;

            activateReel(reelsModule, currentReel);
        });
    });
}

function activateReel(reelsModule, index) {
    const navigation = reelsModule.querySelectorAll('.js-reels-index');
    const reels = reelsModule.querySelectorAll('.js-reel-video');

    navigation.forEach(item => {
        if(item.dataset.reelIndex != index) {
            item.classList.remove('is-active');
        } else item.classList.add('is-active');
    });

    reels.forEach(item => {
        if(item.dataset.reelIndex != index) {
            item.classList.remove('is-active');
        } else item.classList.add('is-active');
    });
}

// ***


// *** INDEX VIDEOS

const indexItems = document.querySelectorAll('.js-index-item');
const indexToggle = document.querySelectorAll('.js-index-toggle');
const indexVideo = document.querySelectorAll('.js-index-video');

if (indexToggle.length > 0) {
    indexToggle.forEach((indexToggle) => {
        // Add touch device experience
        if (touchDevice) {
            // Activate video on click
            indexToggle.addEventListener('click', (event) => {

                const element = event.currentTarget;
                const $parent = element.parentElement;
                const $player = $parent.querySelector('.index__player');


                // Activate if not activated
                if ($parent && !$player.matches('.is-active')) {
                    // Active index accordion
                    showIndexVideo(element);

                    // Load and play vimeo video
                    const videoPlayer = $parent.querySelector('.index__player');
                    activateVideoPlayer(videoPlayer);
                } else {
                    // Close all items
                    hideAllIndexVideos();

                    // Reset all vimeo players
                    resetAllVideoPlayers();
                }
            });
        }

        // Add hover device experience
        if (!touchDevice) {
            let loadingDelay;
            let eventTriggered = false;

            // Open video player on hover
            indexToggle.addEventListener('mouseenter', (event) => {
                const element = event.currentTarget;

                if (!eventTriggered) { // Check if the event has not been triggered before
                    eventTriggered = true; // Set the flag to true

                    // Add a loading delay of 50ms
                    loadingDelay = setTimeout(() => {
                        showIndexVideo(element);
                    }, 50);
                } else showIndexVideo(element);
            });

            // Close video player on hover
            indexToggle.addEventListener('mouseleave', (event) => {
                // Cancel loading delay
                clearTimeout(loadingDelay);

                const element = event.currentTarget;
                const $parent = element.parentElement;
                const $player = $parent.querySelector('.index__player');

                // Hide video if not activated
                if (!$player.matches('.is-active')) {
                    // Reset all vimeo players
                    resetAllVideoPlayers();

                    $parent.classList.remove('is-active'); // Toggle the 'is-active' class
                }
            });

            // Activate video on click
            indexToggle.addEventListener('click', (event) => {
                const element = event.currentTarget;
                const $parent = element.parentElement;
                const videoPlayer = $parent.querySelector('.index__player');
                activateVideoPlayer(videoPlayer);
            });
        }
    });
}


if (indexVideo.length > 0) {
    indexVideo.forEach((indexVideo) => {
        // Add hover and desktop device experience
        if (!touchDevice && isBreakpointLg()) {
            // Deactivate and close video on click
            indexVideo.addEventListener('click', (event) => {
                const element = event.currentTarget;
                // Reset if has playing video
                if (element.matches('.has-active-video')) {
                    // Reset all vimeo players
                    resetAllVideoPlayers();

                    // Hide all index videos
                    hideAllIndexVideos();
                }
            });
        }
    });
}

// Function to remove active class from all items with the class .js-index-item
function showIndexVideo(element) {
    const $parent = element.parentElement; // Find the closest ancestor with the class 'index__item'

    // Close all items
    hideAllIndexVideos();

    // Reset all vimeo players
    resetAllVideoPlayers();

    if ($parent) {
        $parent.classList.toggle('is-active'); // Toggle the 'is-active' class
    }
}

// Hide all index videos
function hideAllIndexVideos() {
    indexItems.forEach((indexItem) => {
        indexItem.classList.remove('is-active');
    });
}

// ***



// *** VARIABLE HEADLINES

// Add unitless viewport size to body for variable font adjustments
// Function to update the --vw custom property
function updateViewportWidth() {
    const vw = window.innerWidth;
    document.body.style.setProperty('--vw', vw);
}

// ***



// *** SECTION TITLES

// Function to add the 'in-view' class when the element is in the viewport
function addInViewClass(entries, observer) {
    entries.forEach((entry) => {
        if (entry.isIntersecting) {
            entry.target.classList.add('is-in-view');
        } else {
            entry.target.classList.remove('is-in-view');
        }
    });
}

// Options for the Intersection Observer
const inViewOptions = {
    root: null, // Use the viewport as the root
    rootMargin: '-60% 0px 0px 0px', // top right bottom left
    threshold: 0, // Trigger when a single pixel of the element is in the viewport
};

// Target elements
const sections = document.querySelectorAll('.js-intersecting-section');

// Create an Intersection Observer for each section
sections.forEach((section) => {
    const observer = new IntersectionObserver(addInViewClass, inViewOptions);
    observer.observe(section);
});

// ***



// *** NAV

// *** NAV

// Cache the frequently used DOM elements
const navList = document.querySelector('.nav-home__list');
const navItems = document.querySelectorAll('.nav-home__item');
const nav = document.querySelector('.js-nav');
const navSections = document.querySelectorAll('[data-nav-section]');

// Function to update the active navigation item and --nav-left custom property
function updateActiveNavItem(entries, observer) {
    let activeNavItem = null;

    entries.forEach((entry) => {
        if (entry.isIntersecting) {
            const navSection = entry.target.getAttribute('data-nav-section');

            // Check if an associated nav item exists
            const matchingNavItem = document.querySelector(`[data-nav-item="${navSection}"]`);

            if (matchingNavItem) {
                activeNavItem = matchingNavItem;
            }
        }
    });

    if (activeNavItem) {
        // Remove 'is-active' class from all navigation items
        navItems.forEach((navItem) => {
            navItem.classList.remove('is-active');
        });

        if (isBreakpointLg()) {
            // Add 'is-active' class to the corresponding navigation item
            activeNavItem.classList.add('is-active');

            // Calculate the left offset for the active menu item inside the nav-list
            const listRect = navList.getBoundingClientRect();
            const itemRect = activeNavItem.getBoundingClientRect();
            const navLeft = itemRect.left - listRect.left;

            // Update nav pos
            nav.style.setProperty('--nav-left', `${navLeft}px`);

        } else {
            // Set active items for mobile
            navList.setAttribute('data-active-item', activeNavItem.getAttribute('data-nav-item')); // mobile
        }
    }
}

// Options for the Intersection Observer
const navOptions = {
    root: null, // Use the viewport as the root
    rootMargin: '-30% 0px -60% 0px', // add in view if 30% scrolled in and remove if 40% scrolled out on bottom
    threshold: 0, // Trigger when 50% of the element is in the viewport
};

// Create an Intersection Observer for each nav section
navSections.forEach((section) => {
    const observer = new IntersectionObserver(updateActiveNavItem, navOptions);
    observer.observe(section);
});

// ***

// ***


// *** ABOUT GALLERY

const gallery = document.querySelector('.js-about-gallery');
let isDragging = false;
let startX;
let scrollLeft;

if (gallery) {
    gallery.addEventListener('mousedown', (e) => {
        isDragging = true;
        gallery.classList.add('grabbing');
        activateGallery();
        startX = e.clientX;
        scrollLeft = gallery.scrollLeft;
    });

    gallery.addEventListener('mouseleave', () => {
        isDragging = false;
        gallery.classList.remove('grabbing');
    });

    gallery.addEventListener('mouseup', () => {
        isDragging = false;
        gallery.classList.remove('grabbing');
    });

    gallery.addEventListener('mousemove', (e) => {
        if (!isDragging) return;
        e.preventDefault();
        const x = e.clientX;
        const walk = (x - startX);
        gallery.scrollLeft = scrollLeft - walk;
    });

    gallery.addEventListener('mouseenter', (e) => {
        activateGallery();
    }, {once: true});

    gallery.addEventListener('click', (e) => {
        activateGallery();
    }, {once: true});

    gallery.addEventListener('touchstart', (e) => {
        activateGallery();
    }, {passive: true, once: true});
}

// Function to add the 'in-view' class when the element is in the viewport
function activateGallery() {
    gallery.classList.add('is-active');

    // Get all img elements inside the gallery
    const imgElements = gallery.querySelectorAll('img');

    // Loop through each img element and remove the loading attribute
    imgElements.forEach((img) => {
        img.removeAttribute('loading');
    });
}

// ***


/// *** ANCHOR LINKS

// Get all anchor links on the page
const anchorLinks = document.querySelectorAll('a[href^="#"]');

// Add a click event listener to each anchor link
anchorLinks.forEach((link) => {
    link.addEventListener('click', (event) => {

        // Toggle a class on the body element
        document.body.classList.toggle('is-anchor-scrolling');

        // Remove the class after a specific duration if needed
        setTimeout(() => {
            document.body.classList.remove('is-anchor-scrolling');
        }, 2000);
    });
});


// ***



// *** HELPER FUNCTIONS

// Function to check if the viewport width is equal or greater than breakpointLgMinWidth
function isBreakpointLg() {
    return window.innerWidth >= breakpointLgMinWidth;
}

/// ***